import React from "react";
import "./style.scss";

interface SectionCoTitleProps {
  title: String;
}

export const SectionCoTitle: React.FC<SectionCoTitleProps> = ({ title }) => {
  return (
    <div className="section-co-title">
      <div className="font-bold text-uppercase">
        <span className="text-primary">CO.</span>
        {title}
      </div>
    </div>
  );
};
