import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import LayoutHome from "../../components/Home/Layout";
import HomePage from "../../components/Home/intex";
import { ThemeService } from "../../services/theme";
import Footer from "../../components/Shared/Footer";

const HomeTemplate = ({ data }) => {
  const [version, setVersion] = useState<number>(0);

  const { siteConfig } = data;
  const { seo } = data.home.tabs;
  seo.slug = { current: "" };

  useEffect(() => {
    initTheme();
  }, []);

  useEffect(() => {
    ThemeService.setThemeVersion(version);
  }, [version]);

  const initTheme = (version?: number) => {
    ThemeService.init();
    setVersion(version ?? ThemeService.getThemeVersion());
  };

  // const changeVersion = (version?: number) => {
  //   initTheme(version);
  // };

  return (
    <LayoutHome siteConfig={siteConfig} seoPage={seo}>
      <main>
        <HomePage version={version} siteConfig={siteConfig} />
      </main>
      <Footer siteConfig={siteConfig} />
    </LayoutHome>
  );
};

export const pageQuery = graphql`
  query HomePageQuery {
    siteConfig: sanitySiteConfig {
      lang
      title
      description
      url
      address
      email
      phone
      whatsapp
      instagram
      linkedin
      mainImage {
        asset {
          url
        }
      }
    }
    home: sanityHome {
      title
      slug {
        current
      }
      tabs {
        seo {
          meta_title
          meta_description
          article
          seo_image {
            asset {
              url
            }
          }

          includeInSitemap
        }
      }
    }
  }
`;

export default HomeTemplate;
