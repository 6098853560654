import React from "react";
import "./animation-hero.scss";

const AnimationHero: React.FC = () => {
  return (
    <svg
      id="eAgsnel9pVf1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 960 540"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
    >
      <g>
        <g id="eAgsnel9pVf3_to" transform="translate(402.976723,263.163834)">
          <g transform="translate(-182.976722,-263.163833)">
            <path
              d="M109.78,223.83c10.05-11.92,25.86-17.69,41.17-17.9c13.68-.41,28.22,3.15,38.3,12.88c8.03,7.29,12.49,17.65,14.43,28.16-8.02.06-16.04.01-24.06.03-2.53-9.19-9.49-17.43-18.93-19.89-8.32-2.01-17.78-1.51-24.97,3.54-8.19,5.42-12.98,14.81-14.37,24.36-1.06,8.4-.69,17.19,2.47,25.13c2.69,7,7.7,13.23,14.4,16.73c7.18,3.72,15.79,4.06,23.52,2.01c9.18-2.65,16.25-10.7,18.14-20c8.02,0,16.04-.01,24.06,0-1.78,9.46-5.61,18.73-12.2,25.87-9.33,10.14-23.18,15.35-36.81,15.62-14.61.6-29.97-3.33-40.96-13.37-9.52-7.92-14.07-20.14-16.38-31.97-3.19-17.66.09-37.41,12.19-51.2Z"
              fill="#e84925"
            />
            <path
              d="M250.46,210.47c4.94-2.1,10.22-3.17,15.5-3.97c1.16,6.55,1.87,13.18,3.04,19.74-8.22,1.48-16,6.02-20.51,13.17-5.97,9.61-7.51,21.52-5.65,32.57c1.55,9.55,7.14,18.6,15.54,23.59c3.38,2.08,7.33,2.77,11.13,3.69-1.28,6.6-2.46,13.22-3.83,19.8-16.02-1.94-31.55-10.77-39.82-24.88-8.42-14.36-9.55-32.18-5.6-48.11c3.66-15.76,15.14-29.57,30.2-35.6Z"
              fill="#e84925"
            />
          </g>
        </g>
        <g id="eAgsnel9pVf6_to" transform="translate(528.538727,263.249992)">
          <path
            d="M802.27,209.98c13.41,1.67,26.7,7.73,35.23,18.46c11.8,14.47,15.09,34.65,11.33,52.65-2.57,13.27-10.63,25.34-21.9,32.79-7.26,4.91-15.81,7.53-24.45,8.64-1.2-6.57-2.41-13.14-3.71-19.69c5-1.12,10.18-2.47,14.23-5.79c5.35-4.51,9.73-10.43,11.48-17.29c2.99-10.59,2.15-22.21-2.14-32.33-4.08-9.23-12.84-15.97-22.75-17.76.83-6.57,1.85-13.11,2.68-19.68Z"
            transform="translate(-824.538727,-266.249992)"
            fill="#e84925"
          />
        </g>
      </g>
      <g>
        <g id="eAgsnel9pVf8_to" transform="translate(824.824357,269.986672)">
          <g
            id="eAgsnel9pVf8"
            transform="translate(-524.824356,-269.986671)"
            opacity="0.91"
          >
            <path
              id="eAgsnel9pVf9"
              d="M696.45,256.5c4.92-4.49,12.32-4.75,18.45-3.05c5.01,1.43,8.66,6.24,8.94,11.4.51,8.54-.24,17.12.47,25.66-3.06-.01-6.11-.01-9.16-.03-.04-1.03-.11-3.09-.15-4.12-4.75,6-14.52,7.02-20.21,1.84-4.65-4.03-4.6-12.28.44-15.98c4.96-3.87,11.65-2.97,17.39-4.69c2.15-.58,1.86-3.45.74-4.85-1.91-2.4-5.49-2.59-8.21-1.72-2.56.7-3.49,3.42-4.69,5.48-2.88-.65-5.76-1.27-8.62-1.94c1.05-2.9,2.19-5.94,4.61-8m7.34,19.38c-3.14,1.16-3.27,6.28-.23,7.65c2.97,1.26,6.8.43,8.94-2.01c1.71-2.12,1.51-5.06,1.96-7.6-3.56.59-7.25.74-10.67,1.96Z"
              opacity="0"
            />
            <path
              id="eAgsnel9pVf10"
              d="M676.39,235.46c4.29-1.33,8.72,3.19,7.51,7.45-.91,4.26-6.82,6.31-10,3.13-3.8-2.88-2.09-9.59,2.49-10.58Z"
              transform="translate(0 1)"
              opacity="0"
            />
            <path
              id="eAgsnel9pVf11"
              d="M672.98,253.62c3.32,0,6.65,0,9.98,0c0,12.3,0,24.59,0,36.88-3.33,0-6.66,0-9.98,0c0-12.29-.01-24.58,0-36.88Z"
              opacity="0"
            />
            <path
              id="eAgsnel9pVf12"
              d="M634.77,257.77c6.96-6.91,19.71-7.21,26.58-.02c1.97,1.97,3.09,4.58,4.26,7.07-2.94,1.03-5.9,2.01-8.85,3.01-1.23-1.89-2.2-4.22-4.33-5.26-3.77-1.75-8.75-.82-11.33,2.54-2.56,3.3-2.71,8.05-1.24,11.84c1.98,5.09,8.84,7.05,13.4,4.25c1.67-1.25,2.68-3.16,3.87-4.84c2.9.95,5.8,1.92,8.68,2.94-1.68,3.88-4.02,7.7-7.8,9.84-7.24,4.17-17.27,2.99-23.25-2.89-7.72-7.33-7.72-21.16.01-28.48Z"
              opacity="0"
            />
            <path
              id="eAgsnel9pVf13"
              d="M597.42,257.7c4.2-4.93,11.7-6.51,17.48-3.58c4.32,2.33,6.49,7.31,6.62,12.05.22,8.11.01,16.22.08,24.33-3.33,0-6.65,0-9.97,0-.11-7.52.19-15.06-.11-22.58-.07-3.04-2.33-6.07-5.53-6.22-3.9-.83-7.82,2.32-7.93,6.28-.4,7.49-.03,15.01-.16,22.52-3.32,0-6.65,0-9.97,0c0-12.29,0-24.58,0-36.88c3.25,0,6.49,0,9.75.01-.09,1.35-.17,2.71-.26,4.07Z"
              opacity="0"
            />
            <path
              id="eAgsnel9pVf14"
              d="M550.49,256.71c7.54-6.65,21.06-5.38,26.54,3.3c2.86,4.29,2.99,9.62,3.05,14.6-8.99.07-17.98-.07-26.97.08c1,4.62,4.87,8.75,9.89,8.34c3.82.29,6.67-2.47,8.49-5.51c2.74.83,5.48,1.65,8.23,2.47-1.51,3.18-3.28,6.43-6.29,8.43-6.3,4.41-15.35,4.16-21.65-.11-10.32-6.83-10.82-23.89-1.29-31.6m6.51,5.38c-1.92,1.34-2.68,3.68-3.66,5.69c5.75.15,11.5.14,17.26.01-.98-2.26-1.7-5.02-4.1-6.21-2.95-1.44-6.76-1.39-9.5.51Z"
              opacity="0"
            />
            <path
              id="eAgsnel9pVf15"
              d="M529.34,235.43c4.23-1.22,8.57,3.21,7.42,7.42-.85,4.24-6.68,6.37-9.9,3.28-3.96-2.85-2.21-9.77,2.48-10.7Z"
              opacity="0"
            />
            <path
              id="eAgsnel9pVf16"
              d="M525.83,253.62c3.32,0,6.65,0,9.98,0c0,12.3,0,24.59,0,36.88-3.33,0-6.66,0-9.98,0c0-12.29,0-24.58,0-36.88Z"
              opacity="0"
            />
            <path
              id="eAgsnel9pVf17"
              d="M496.28,253.62c3.24,0,6.48,0,9.73.01-.06,1.69-.11,3.4-.17,5.1c2.68-4.53,7.94-5.85,12.89-5.48.05,3.35.05,6.7.07,10.05-2.98.06-6.26-.25-8.84,1.55-2.78,1.68-3.62,5.1-3.67,8.13-.14,5.84.02,11.68-.04,17.52-3.32,0-6.65,0-9.97,0c0-12.29,0-24.58,0-36.88Z"
              opacity="0"
            />
            <path
              id="eAgsnel9pVf18"
              d="M458.8,256.74c7.62-6.77,21.46-5.38,26.78,3.6c2.69,4.23,2.76,9.42,2.88,14.26-8.99.08-17.99-.05-26.98.08.97,4.91,5.37,9.1,10.61,8.31c3.55.05,6.03-2.69,7.76-5.45c2.74.81,5.48,1.63,8.23,2.45-1.48,3.03-3.1,6.16-5.93,8.16-6.36,4.76-15.83,4.51-22.26,0-10.09-6.93-10.51-23.74-1.09-31.41m6.96,5.08c-2.18,1.29-3.04,3.8-4.12,5.94c5.77.17,11.55.18,17.32-.01-.98-2.08-1.57-4.65-3.69-5.91-2.85-1.68-6.66-1.7-9.51-.02Z"
              opacity="0"
            />
            <path
              id="eAgsnel9pVf19"
              d="M418.41,257.69c6.76-7.46,20.37-6.22,25.43,2.54c5.17,8.77,4.02,21.79-4.51,28.09-6.02,4.19-15.18,4.31-20.5-1.13.09,5.85.04,11.71.05,17.56-3.33,0-6.65,0-9.98,0c0-17.04,0-34.08.01-51.13c3.24,0,6.49,0,9.74.01-.08,1.35-.16,2.7-.24,4.06m5.99,4.78c-6.12,2.45-7.14,10.89-3.9,16c2.63,4.31,9.3,5.4,13.12,2.07c4.38-3.96,4.7-11.63.83-16.04-2.47-2.71-6.72-3.5-10.05-2.03Z"
              opacity="0"
            />
            <path
              id="eAgsnel9pVf20"
              d="M364.58,253.63c3.94-.01,7.87-.01,11.81-.01c2.43,3.64,4.92,7.25,7.39,10.87c2.42-3.63,4.85-7.25,7.28-10.87c3.77,0,7.54,0,11.31.01-4.29,6-8.59,11.99-12.89,17.98c4.43,6.3,8.9,12.58,13.33,18.89-3.9,0-7.79,0-11.68.01-2.58-3.8-5.16-7.6-7.73-11.4-2.54,3.8-5.03,7.61-7.58,11.4-3.75-.01-7.49-.01-11.24-.01c4.37-6.17,8.75-12.33,13.12-18.5-4.37-6.12-8.75-12.25-13.12-18.37Z"
              opacity="0"
            />
            <path
              id="eAgsnel9pVf21"
              d="M332.46,256.46c7.64-6.41,21.05-5.01,26.35,3.73c2.75,4.27,2.94,9.51,2.91,14.43-8.98.06-17.97-.08-26.95.08c1.06,4.72,5.11,8.93,10.26,8.32c3.7.2,6.3-2.63,8.15-5.46c2.74.79,5.49,1.61,8.23,2.43-1.36,2.66-2.71,5.44-5.03,7.41-6.37,5.6-16.64,5.47-23.39.59-10.01-7.12-10.21-24.04-.53-31.53m6.45,5.46c-2.06,1.31-2.85,3.76-3.95,5.8c5.78.26,11.58.19,17.37.05-1.02-2.19-1.74-4.85-4-6.11-2.88-1.5-6.65-1.5-9.42.26Z"
              opacity="0"
            />
          </g>
        </g>
        <g id="eAgsnel9pVf22_to" transform="translate(524.655427,324.946213)">
          <g
            id="eAgsnel9pVf22"
            transform="translate(-524.655426,-344.946213)"
            opacity="0"
          >
            <path d="M581.42,317.42c4.24-2.44,9.83,1.85,8.82,6.56-.56,4.43-6.57,6.81-9.9,3.71-3.53-2.51-2.74-8.45,1.08-10.27Z" />
            <path d="M459.39,372c0-18.09,0-36.17,0-54.25c3.32,0,6.65,0,9.97,0-.03,6.69.09,13.38-.09,20.07c4.48-3.79,10.98-4.77,16.38-2.46c4.77,2.14,7.29,7.49,7.34,12.54.21,8.03.01,16.07.08,24.1-3.33,0-6.66,0-9.98,0-.03-6.66.03-13.32.01-19.98-.04-2.5-.13-5.35-2.06-7.21-3.57-3.38-10.58-1.55-11.37,3.55-.74,7.84-.09,15.77-.31,23.64-3.33,0-6.65,0-9.97,0Z" />
            <path d="M618.18,319.37c3.79,0,7.58,0,11.37.01-3.39,3.75-6.79,7.49-10.18,11.24-2.68,0-5.36.01-8.04.01c2.28-3.76,4.56-7.51,6.85-11.26Z" />
            <path d="M377.95,339.08c6.92-6.62,19.22-6.95,26.1-.12c2.13,2.02,3.33,4.75,4.55,7.37-2.93,1.02-5.88,1.99-8.82,2.98-1.33-1.99-2.4-4.5-4.79-5.43-3.83-1.54-8.71-.43-11.12,3.02-2.44,3.46-2.49,8.28-.8,12.06c2.18,4.63,8.43,6.32,12.83,3.95c1.9-1.19,2.94-3.27,4.19-5.05c2.91.95,5.82,1.91,8.72,2.91-1.5,3.29-3.25,6.65-6.26,8.81-7.33,5.51-18.68,4.44-25.06-2.08-7.54-7.47-7.36-21.22.46-28.42Z" />
            <path d="M427.48,334.52c10.85-2.98,23.1,5.1,23.84,16.49c1.75,10.49-6.4,21.5-17.2,21.97-10.19,1.45-20.49-6.58-21.12-16.94-1.49-9.5,4.86-19.65,14.48-21.52m1.83,8.97c-9.14,2.7-8.64,18.63.92,20.34c6.38,1.64,12.12-4.66,11.28-10.86.37-6.25-6.15-11.66-12.2-9.48Z" />
            <path d="M507.26,338.18c7.67-6.76,21.56-5.26,26.8,3.82c2.61,4.21,2.69,9.31,2.77,14.11-9.02.1-18.03-.15-27.04.15c1.12,2.88,2.4,6.16,5.54,7.36c4.74,2.38,10.67.2,12.85-4.6c2.76.82,5.53,1.64,8.29,2.5-1.42,2.77-2.85,5.69-5.35,7.66-6.31,5.22-16.12,5.11-22.75.54-10.2-6.9-10.64-23.87-1.11-31.54m7,5.07c-2.26,1.27-3.13,3.86-4.23,6.04c5.79.14,11.59.11,17.39.02-1.05-2.11-1.66-4.74-3.83-6.01-2.8-1.61-6.53-1.66-9.33-.05Z" />
            <path d="M545.94,337.97c4.98-4.89,13.18-5.02,19.1-1.83c3.18,1.71,4.68,5.17,6.05,8.33-2.75.69-5.5,1.33-8.25,1.96-1.42-2.52-3.44-5.46-6.79-4.84-3.01-.36-5.97,3.53-3.46,5.91c4.88,2.33,11.06,1.76,15.19,5.73c4.6,3.94,4.38,11.62.16,15.77-4.76,4.86-12.6,4.95-18.63,2.72-4.31-1.58-6.77-5.75-8.06-9.91c2.77-.64,5.54-1.25,8.33-1.86c1.16,1.93,2.02,4.55,4.51,5.13c2.49.75,6.1.6,7.36-2.09.75-1.78-.34-3.68-2.11-4.2-4.62-1.74-10.17-1.68-13.87-5.36-4.23-4.08-3.79-11.56.47-15.46Z" />
            <path d="M611.43,334.62c6.38-1.45,13.66-.16,18.4,4.58c8.36,7.64,7.74,22.54-1.01,29.64-7.92,6.76-21.44,5.51-27.66-2.97-3.95-5.05-4.88-11.98-3.32-18.1c1.68-6.4,7.1-11.73,13.59-13.15m2.9,8.61c-8.72,1.9-9.84,15.67-2.28,19.74c3.33,1.72,7.84,1.36,10.56-1.35c3.73-3.53,4.1-9.67,1.71-14.04-1.9-3.47-6.16-5.31-9.99-4.35Z" />
            <path d="M653.18,339.22c4.64-5.62,13.94-6.95,19.57-2.1c3.36,2.95,4.57,7.55,4.56,11.89.1,7.66-.01,15.32.03,22.99-3.33,0-6.66,0-9.98,0-.08-7.35.15-14.7-.07-22.04.06-3.09-1.97-6.42-5.26-6.71-3.76-.92-7.87,1.83-8.17,5.75-.55,7.64-.06,15.33-.22,23-3.33,0-6.66,0-9.98,0c0-12.29,0-24.59,0-36.88c3.24,0,6.49,0,9.74.01-.08,1.36-.15,2.72-.22,4.09Z" />
            <path d="M579.24,335.12c3.32,0,6.64,0,9.97,0c.01,12.29,0,24.59.01,36.88-3.34,0-6.66,0-9.99,0c.01-12.29,0-24.59.01-36.88Z" />
          </g>
        </g>
        <g id="eAgsnel9pVf33_to" transform="translate(524.762512,324.925827)">
          <g
            id="eAgsnel9pVf33"
            transform="translate(-524.762512,-424.925827)"
            opacity="0"
          >
            <path
              id="eAgsnel9pVf34"
              d="M706.5,418.24c-.21-6.83-.07-13.66-.1-20.49c3.27,0,6.55,0,9.83,0c.13,18.08-.28,36.17.23,54.25-3.2,0-6.38,0-9.57,0-.06-1.29-.1-2.57-.13-3.85-5.58,6.55-16.33,5.85-22.32.28-8.81-8-8.16-24.3,1.72-31.17c6.01-4,14.98-4.37,20.34.98m-12.12,5.4c-6.5,2.19-7.53,11.13-4.36,16.4c2.79,4.92,10.49,5.22,14.01,1c2.85-3.5,3.13-8.62,1.55-12.72-1.71-4.25-6.99-6.26-11.2-4.68Z"
            />
            <path
              id="eAgsnel9pVf35"
              d="M650.41,414.51c7.5-1.76,17.72.41,20.03,8.84c1.63,9.44-.03,19.13,1.06,28.66-3.07-.01-6.13-.01-9.18-.04-.08-1.39-.14-2.78-.19-4.15-4.61,6.03-14.26,7.02-19.97,2.06-4.66-3.88-4.92-11.96-.15-15.85c4.18-3.57,9.98-3.44,15.11-4.37c1.68-.36,4.09-.45,4.33-2.65.03-4.6-5.6-5.91-9.13-4.55-2.55.71-3.49,3.38-4.64,5.45-2.89-.61-5.78-1.23-8.65-1.85c1.35-5.57,5.62-10.43,11.38-11.55m.85,22.76c-3.23.91-3.68,5.87-.89,7.56c2.65,1.45,6.17.79,8.5-1.02c2.5-2,2.3-5.52,2.75-8.37-3.46.52-7.02.72-10.36,1.83Z"
            />
            <path
              id="eAgsnel9pVf36"
              d="M620.47,418.24c-.2-6.83-.07-13.66-.1-20.49c3.27,0,6.55,0,9.83,0c.14,18.08-.29,36.17.24,54.25-3.2,0-6.39,0-9.58,0-.06-1.28-.1-2.55-.12-3.82-5.76,6.68-16.85,5.76-22.78-.17-6.84-6.72-7.47-18.45-2.23-26.3c5.09-7.94,17.79-10.28,24.74-3.47m-12.04,5.37c-6.53,2.14-7.61,11.07-4.48,16.37c2.65,4.74,9.83,5.28,13.56,1.59c3.31-3.42,3.7-8.91,2.06-13.21-1.69-4.25-6.92-6.29-11.14-4.75Z"
            />
            <path
              id="eAgsnel9pVf37"
              d="M576.35,397.54c3.9-2.43,9.3.95,9.16,5.44.17,4.64-5.82,7.95-9.55,5.04-3.88-2.25-3.46-8.44.39-10.48Z"
            />
            <path
              id="eAgsnel9pVf38"
              d="M574.4,415.12c3.32,0,6.64,0,9.98,0c0,12.29-.01,24.59,0,36.88-3.34,0-6.66,0-9.99,0c.01-12.29.01-24.59.01-36.88Z"
            />
            <path
              id="eAgsnel9pVf39"
              d="M540.11,419.23c4.66-5.65,14.03-6.98,19.65-2.05c3.33,2.97,4.5,7.56,4.49,11.88.09,7.64-.02,15.29.02,22.94-3.33,0-6.65,0-9.97,0-.03-6.67.02-13.34.02-20-.02-2.73-.24-5.94-2.64-7.68-3.95-2.87-10.54-.48-10.88,4.66-.55,7.65-.07,15.35-.22,23.02-3.33,0-6.66,0-9.98,0-.01-12.29,0-24.59,0-36.88c3.24,0,6.49,0,9.74.01-.08,1.36-.16,2.72-.23,4.1Z"
            />
            <path
              id="eAgsnel9pVf40"
              d="M486.25,415.13c3.33-.01,6.66-.01,10,0c.03,6.64-.03,13.28-.01,19.93c0,2.59.23,5.57,2.39,7.36c3.84,3.13,10.95.9,11.17-4.43.49-7.61.05-15.25.2-22.87c3.33,0,6.66,0,10,0c.13,12.29-.28,24.6.24,36.88-3.18,0-6.36,0-9.54-.01-.05-1.2-.09-2.4-.12-3.59-5.33,6.28-16.47,6.1-21.37-.6-2.37-3.04-2.96-7.01-2.97-10.76-.02-7.3.03-14.61.01-21.91Z"
            />
            <path
              id="eAgsnel9pVf41"
              d="M429.9,419.12c5.46-6.53,17.38-7.25,21.9.7c4.34-6.22,13.67-7.67,19.79-3.31c3.66,2.55,5.23,7.1,5.2,11.42.11,8.02-.01,16.05.04,24.07-3.23,0-6.46,0-9.68,0-.19-7.97.37-15.97-.27-23.91-.6-5.04-7.62-6.56-10.91-3.17-1.95,1.8-2.18,4.59-2.19,7.08-.02,6.66.04,13.33.02,20-3.3,0-6.6,0-9.9,0-.1-7.58.18-15.17-.1-22.75.03-3.59-3.27-6.61-6.86-6.1-3.65-.07-6.48,3.37-6.4,6.88-.2,7.32.01,14.64-.06,21.97-3.33,0-6.66,0-9.98,0c0-12.29,0-24.59,0-36.88c3.19,0,6.38,0,9.57,0-.06,1.33-.12,2.66-.17,4Z"
            />
            <path
              id="eAgsnel9pVf42"
              d="M388.43,414.55c6.14-1.37,13.07-.15,17.77,4.21c8.48,7.3,8.54,22.09.13,29.46-7.8,7.29-21.77,6.31-28.27-2.25-4.09-5.06-4.94-12.14-3.33-18.31c1.73-6.44,7.19-11.68,13.7-13.11m1.89,8.97c-8.95,2.78-8.54,18.31.78,20.26c6.42,1.81,12.37-4.47,11.52-10.76.36-6.31-6.19-11.78-12.3-9.5Z"
            />
            <path
              id="eAgsnel9pVf43"
              d="M338.58,419.54c6.51-6.7,18.25-7.5,25.5-1.57c2.72,2.12,4.25,5.29,5.64,8.36-2.94,1.02-5.89,2-8.84,2.98-1.07-1.64-1.94-3.51-3.51-4.74-4.15-2.67-10.4-1.33-12.85,3.02-2.01,3.57-2.01,8.26-.07,11.85c2.36,4.31,8.35,5.74,12.58,3.46c1.89-1.2,2.93-3.28,4.18-5.05c2.91.96,5.81,1.92,8.71,2.93-1.51,3.31-3.28,6.69-6.33,8.85-7.35,5.45-18.68,4.37-25.03-2.17-7.33-7.33-7.33-20.61.02-27.92Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
export default AnimationHero;
