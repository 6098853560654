import React, { useEffect, useState } from "react";
import { ComponentVersion } from "../../../interfaces/theme";
import "./community.scss";

const community = [
  {
    img: "https://res.cloudinary.com/mosconi-digital/image/upload/e_grayscale,f_auto/v1635891396/Codevelopers/web/Sebastian_Lorenzo_giqsn7.jpg",
    name: "Sebastian Lorenzo",
    position: "Cofundador",
    contactLink: "https://www.linkedin.com/in/sebastian-lorenzo-3b838712b/",
    class: "",
  },
  {
    img: "https://res.cloudinary.com/mosconi-digital/image/upload/e_grayscale,f_auto/v1635890704/Codevelopers/web/Juan_Ignacio_Suarez_kukuzj.jpg",
    name: "Juan Ignacio Suarez",
    position: "Cofundador",
    contactLink: "https://www.linkedin.com/in/juanignaciosuarezm/",
  },
  // {
  //   img: "https://res.cloudinary.com/mosconi-digital/image/upload/e_grayscale,f_auto/v1635891738/Codevelopers/web/Gabriela_Lucchini_gkdq13.jpg",
  //   name: "Gabriela Lucchini",
  //   position: "Directora ejecutiva",
  //   contactLink: "https://www.linkedin.com/in/glucchini/",
  //   class: "",
  // },
  {
    img: "https://res.cloudinary.com/mosconi-digital/image/upload/e_grayscale,f_auto/v1635891960/Codevelopers/web/Matias_Sukanec_yqkly5.jpg",
    name: "Matías Sukanec",
    position: "Director de  tecnología y arquitectura",
    contactLink: "https://www.linkedin.com/in/glucchini/",
    class: "",
  },
  // {
  //   img: "https://res.cloudinary.com/mosconi-digital/image/upload/e_grayscale,f_auto/v1635892620/Codevelopers/web/Rodrigo_Marti%CC%81nez_Rivero_jdmoec.jpg",
  //   name: "Rodrigo Martínez Rivero",
  //   position: "Arquitecto líder en procesos de creación empática",
  //   contactLink: "",
  //   class: "sm",
  // },
  // {
  //   img: "https://res.cloudinary.com/mosconi-digital/image/upload/f_auto/v1640126991/Codevelopers/web/Alejandro_Graziani_mrqbjv.jpg",
  //   name: "Alejandro Graziani",
  //   position: "Gerente de desarrollos",
  //   contactLink: "https://www.linkedin.com/in/alejandro-graziani/",
  //   class: "",
  // },
  {
    img: "https://res.cloudinary.com/mosconi-digital/image/upload/e_gamma:70,f_auto/v1640126990/Codevelopers/web/Ayele%CC%81n_Novaresio_zm4m7r.jpg",
    name: "Ayelén Novaresio",
    position: "Directora de Comunicación y RRII",
    contactLink: "https://www.linkedin.com/in/ayelennovaresio/",
    class: "",
  },
  // {
  //   img: "https://res.cloudinary.com/mosconi-digital/image/upload/e_gamma:30,f_auto/v1640126991/Codevelopers/web/Gonzalo_Soberon_oy6a2d.jpg",
  //   name: "Gonzalo Soberon",
  //   position: "Especialista en tecnología y visualización",
  //   contactLink:
  //     "https://www.linkedin.com/in/gonzalo-manuel-soberón-1b2938179/",
  //   class: "sm",
  // },
  {
    img: "https://res.cloudinary.com/mosconi-digital/image/upload/e_gamma:30,f_auto/v1640126991/Codevelopers/web/Manuel_Depierro_e4ji7a.jpg",
    name: "Manuel Depierro",
    position: "Director de Proyecto y Construcción",
    contactLink:
      "https://www.linkedin.com/in/domingo-manuel-depierro-a2172913/",
    class: "",
  },
  {
    img: "https://res.cloudinary.com/mosconi-digital/image/upload/e_grayscale,f_auto/v1680308716/Codevelopers/web/diego-benvenuto_nyxau0.jpg",
    name: "Diego Benvenuto",
    position: "Director de Obra y Producción",
    contactLink: "https://www.linkedin.com/in/dbenvenuto82/",
    class: "",
  },
  {
    img: "https://res.cloudinary.com/mosconi-digital/image/upload/f_auto/v1681856519/Codevelopers/web/andres_lloren_rpdqug.jpg",
    name: "Andrés Lloren",
    position: "Director de Relación con los inversores",
    // contactLink: "https://www.linkedin.com/in/dbenvenuto82/",
    class: "",
  },
  {
    img: "https://res.cloudinary.com/mosconi-digital/image/upload/e_grayscale,f_auto/v1680786490/Codevelopers/web/soledad_pestarino.jpg",
    name: "Soledad Pestarino",
    position: "Project Manager Officer",
    contactLink: "https://www.linkedin.com/in/solpestarino/",
    class: "",
  },
  {
    img: "https://res.cloudinary.com/mosconi-digital/image/upload/f_auto/v1640126991/Codevelopers/web/Mariela_Moll_Arquitecta_ub7llp.jpg",
    name: "Mariela Moll",
    position: "Arquitecta proyectista",
    contactLink: "",
    class: "",
  },
  {
    img: "https://res.cloudinary.com/mosconi-digital/image/upload/e_gamma:30,f_auto/v1640126991/Codevelopers/web/Michelle_Goral_embi8j.jpg",
    name: "Michelle Goral",
    position: "Arquitecta especialista en diseño de interiores",
    contactLink: "https://www.linkedin.com/in/michelle-goral/",
    class: "sm",
  },
  {
    img: "https://res.cloudinary.com/mosconi-digital/image/upload/e_grayscale,f_auto/v1680308931/Codevelopers/web/agustina-fernandez-vallejo_uvo26z.jpg",
    name: "Agustina Fernández Vallejo",
    position: "Especialista en visualización",
    contactLink:
      "https://www.linkedin.com/in/agustina-fern%C3%A1ndez-vallejo-171688181/",
    class: "",
  },
  {
    img: "https://res.cloudinary.com/mosconi-digital/image/upload/e_grayscale,f_auto/v1680308890/Codevelopers/web/luciano-falcone_opcadi.jpg",
    name: "Luciano Falcone",
    position: "Responsible de tesosería ",
    contactLink: "",
    class: "",
  },
];

const Community: React.FC<ComponentVersion> = ({ version }) => {
  const [scrollTop, setScrollTop] = useState(0);
  const [showCommunity, setShowCommunity] = useState<boolean>(false);

  useEffect(() => {
    const onScroll = () => {
      if (typeof window !== `undefined`) {
        const el = document.getElementById("co-mmunity");

        if (window.pageYOffset - el!.offsetTop + 300 >= 0) {
          setShowCommunity(true);
        }
      }
    };
    if (typeof window !== `undefined`) {
      window.addEventListener("scroll", onScroll);
    }
    return () => {
      if (typeof window !== `undefined`) {
        return window.removeEventListener("scroll", onScroll);
      }
    };
  }, [scrollTop]);

  // const getShape = () => {
  //   const shapeUrl = `v${version}/community-shape.svg`;

  //   switch (version) {
  //     case 2:
  //       return <img src={shapeUrl} className="community-shape" />;
  //     case 4:
  //       return <img src={shapeUrl} className="community-shape" />;
  //     case 5:
  //       return <img src={shapeUrl} className="community-shape" />;
  //     default:
  //       break;
  //   }

  //   return <span />;
  // };

  return (
    // <div className={`community v${version}`} id="co-mmunity">
    //   <div
    //     className={`meet flex flex-column justify-center font-black text-uppercase ${
    //       showCommunity ? "show-community" : ""
    //     }`}
    //   >
    //     <div className="container">
    //       <div className="meet-label">MEET THE</div>
    //       <div className="community-label">
    //         <span className="text-primary">CO·</span>MMUNITY
    //       </div>
    //     </div>
    //   </div>
    //   <div className="infinite-section">
    //     <div className="container">
    //       <SectionCoTitle title="MMUNITY" />
    //     </div>
    //     <div className="container h-100">
    //       <div className="infinity-wrap">
    //         <div className="img-wrap">
    //           <img src="infinity.svg" className="desktop" />
    //           <img src="infinity-vertical.svg" className="mobile" />
    //         </div>

    // {community.map((person, i) => {
    //   return (
    //     <div
    //       className={`item floating font-bold text-uppercase ${person.class}`}
    //       style={{ backgroundImage: `url(${person.img})` }}
    //       key={i}
    //     >
    //       <div className="overlay" />
    //       <div className="text-container">
    //         <div
    //           className="name text-uppercase"
    //           dangerouslySetInnerHTML={{ __html: person.name }}
    //         />
    //         <div
    //           className="position text-uppercase"
    //           dangerouslySetInnerHTML={{ __html: person.position }}
    //         />
    //       </div>

    //       {person.contactLink ? (
    //         <a
    //           className="social"
    //           href={person.contactLink}
    //           target="_blank"
    //         >
    //           <img src="linkedin.svg" alt="" loading="lazy" />
    //         </a>
    //       ) : null}
    //     </div>
    //   );
    // })}
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <section className="community" id="co-mmunity">
      <div className="container">
        <div className="title text-center">
          <h2 className="font-bold">Nosotros</h2>
        </div>

        <div className="persons">
          {community.map((person, i) => {
            return (
              <div
                className={`item floating font-bold text-uppercase ${person.class}`}
                style={{ backgroundImage: `url(${person.img})` }}
                key={i}
              >
                <div className="overlay" />

                <div className="social">
                  <div className="text-container">
                    <div
                      className="name"
                      dangerouslySetInnerHTML={{ __html: person.name }}
                    />
                    <div
                      className="position font-book"
                      dangerouslySetInnerHTML={{ __html: person.position }}
                    />
                  </div>
                  {person.contactLink && (
                    // <img src="linkedin.svg" alt="" loading="lazy" />
                    <a href={person.contactLink}>
                      Linkedin{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 17 14"
                        className="icon"
                      >
                        <path
                          fill="#E94F1B"
                          d="m10 14-1.1-1 5.4-5.3H0V6.3h14.3L8.9 1l1-1L17 7l-7 7Z"
                        />
                      </svg>
                    </a>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Community;
