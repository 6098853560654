import React from "react";
import { ComponentVersion } from "../../../interfaces/theme";
import { scrollToDiv } from "../../../services/utils";
import AnimationHero from "./AnimationHero";
import "./hero.scss";

const HomeHero: React.FC<ComponentVersion> = ({ version }) => {
  return (
    <section className={`hero-container v${version}`} key={`v${version}`}>
      <div className="hero">
        <div className="container">
          <div className="animation-hero">
            <AnimationHero />
          </div>
          <div className="bottom-content">
            <hr />
            <img
              src="arrow-down2.svg"
              className="arrow bounce"
              onClick={() => scrollToDiv("by-everyone")}
            />
            <hr />
            {/* <p className="typewriter">
              CADA VEZ QUE NOS VISITES VA A SER DIFERENTE, PORQUE CADA COSA QUE
              HACEMOS ES DIFERENTE.
            </p> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
