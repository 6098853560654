import React, { useEffect, useState } from "react";
import { InstagramService } from "../../../services/instagram";
import "./instagram.scss";

const postsMock = [
  {
    image:
      "https://res.cloudinary.com/mosconi-digital/image/upload/c_scale,f_auto,w_350/v1637189265/Codevelopers/web/instagram/posts/1_a8bq7u.jpg",
    link: "https://www.instagram.com/p/CWT_qg2P6B_",
  },
  {
    image:
      "https://res.cloudinary.com/mosconi-digital/image/upload/c_scale,f_auto,w_350/v1637189264/Codevelopers/web/instagram/posts/2_koyhea.jpg",
    link: "https://www.instagram.com/p/CWHSW6nDhyC",
  },
  {
    image:
      "https://res.cloudinary.com/mosconi-digital/image/upload/c_scale,f_auto,w_350/v1637189265/Codevelopers/web/instagram/posts/3_jgpgel.jpg",
    link: "https://www.instagram.com/p/CWEqihCBgvs",
  },
  {
    image:
      "https://res.cloudinary.com/mosconi-digital/image/upload/c_scale,f_auto,w_350/v1637189265/Codevelopers/web/instagram/posts/4_jafvpk.jpg",
    link: "https://www.instagram.com/p/CVly3VjJ-zw",
  },
  {
    image:
      "https://res.cloudinary.com/mosconi-digital/image/upload/c_scale,f_auto,w_350/v1637189265/Codevelopers/web/instagram/posts/5_cnf7qb.jpg",
    link: "https://www.instagram.com/p/CVWSKMVJmdq",
  },
  {
    image:
      "https://res.cloudinary.com/mosconi-digital/image/upload/c_scale,f_auto,w_350/v1637189265/Codevelopers/web/instagram/posts/6_fowciu.jpg",
    link: "https://www.instagram.com/p/CVREXQDpnhu",
  },
  {
    image:
      "https://res.cloudinary.com/mosconi-digital/image/upload/c_scale,f_auto,w_350/v1637189265/Codevelopers/web/instagram/posts/7_bzp0ja.jpg",
    link: "https://www.instagram.com/p/CUYWQcAvJdE",
  },
  {
    image:
      "https://res.cloudinary.com/mosconi-digital/image/upload/c_scale,f_auto,w_350/v1637189264/Codevelopers/web/instagram/posts/8_ewv3nt.jpg",
    link: "https://www.instagram.com/p/CUYWNXVveQ-",
  },
  {
    image:
      "https://res.cloudinary.com/mosconi-digital/image/upload/c_scale,f_auto,w_350/v1637189264/Codevelopers/web/instagram/posts/9_duz3mn.jpg",
    link: "https://www.instagram.com/p/CUYWLMLPYc4/",
  },
  {
    image:
      "https://res.cloudinary.com/mosconi-digital/image/upload/c_scale,f_auto,w_350/v1637189264/Codevelopers/web/instagram/posts/10_nmllin.jpg",
    link: "https://www.instagram.com/p/CT5YH9SP2OS",
  },
  {
    image:
      "https://res.cloudinary.com/mosconi-digital/image/upload/c_scale,f_auto,w_350/v1637189264/Codevelopers/web/instagram/posts/11_sxeu1m.jpg",
    link: "https://www.instagram.com/p/CT5YFXXvmnT",
  },
  {
    image:
      "https://res.cloudinary.com/mosconi-digital/image/upload/c_scale,f_auto,w_350/v1637189264/Codevelopers/web/instagram/posts/12_fpwoyc.jpg",
    link: "https://www.instagram.com/p/CT5X_LdPnC_",
  },
];

const postsMockSm = [
  {
    image:
      "https://res.cloudinary.com/mosconi-digital/image/upload/c_scale,f_auto,w_250/v1637189265/Codevelopers/web/instagram/posts/1_a8bq7u.jpg",
    link: "https://www.instagram.com/p/CWT_qg2P6B_",
  },
  {
    image:
      "https://res.cloudinary.com/mosconi-digital/image/upload/c_scale,f_auto,w_250/v1637189264/Codevelopers/web/instagram/posts/2_koyhea.jpg",
    link: "https://www.instagram.com/p/CWHSW6nDhyC",
  },
  {
    image:
      "https://res.cloudinary.com/mosconi-digital/image/upload/c_scale,f_auto,w_250/v1637189265/Codevelopers/web/instagram/posts/3_jgpgel.jpg",
    link: "https://www.instagram.com/p/CWEqihCBgvs",
  },
  {
    image:
      "https://res.cloudinary.com/mosconi-digital/image/upload/c_scale,f_auto,w_250/v1637189265/Codevelopers/web/instagram/posts/4_jafvpk.jpg",
    link: "https://www.instagram.com/p/CVly3VjJ-zw",
  },
  {
    image:
      "https://res.cloudinary.com/mosconi-digital/image/upload/c_scale,f_auto,w_250/v1637189265/Codevelopers/web/instagram/posts/5_cnf7qb.jpg",
    link: "https://www.instagram.com/p/CVWSKMVJmdq",
  },
  {
    image:
      "https://res.cloudinary.com/mosconi-digital/image/upload/c_scale,f_auto,w_250/v1637189265/Codevelopers/web/instagram/posts/6_fowciu.jpg",
    link: "https://www.instagram.com/p/CVREXQDpnhu",
  },
  {
    image:
      "https://res.cloudinary.com/mosconi-digital/image/upload/c_scale,f_auto,w_250/v1637189265/Codevelopers/web/instagram/posts/7_bzp0ja.jpg",
    link: "https://www.instagram.com/p/CUYWQcAvJdE",
  },
  {
    image:
      "https://res.cloudinary.com/mosconi-digital/image/upload/c_scale,f_auto,w_250/v1637189264/Codevelopers/web/instagram/posts/8_ewv3nt.jpg",
    link: "https://www.instagram.com/p/CUYWNXVveQ-",
  },
  {
    image:
      "https://res.cloudinary.com/mosconi-digital/image/upload/c_scale,f_auto,w_250/v1637189264/Codevelopers/web/instagram/posts/9_duz3mn.jpg",
    link: "https://www.instagram.com/p/CUYWLMLPYc4/",
  },
  {
    image:
      "https://res.cloudinary.com/mosconi-digital/image/upload/c_scale,f_auto,w_250/v1637189264/Codevelopers/web/instagram/posts/10_nmllin.jpg",
    link: "https://www.instagram.com/p/CT5YH9SP2OS",
  },
  {
    image:
      "https://res.cloudinary.com/mosconi-digital/image/upload/c_scale,f_auto,w_250/v1637189264/Codevelopers/web/instagram/posts/11_sxeu1m.jpg",
    link: "https://www.instagram.com/p/CT5YFXXvmnT",
  },
  {
    image:
      "https://res.cloudinary.com/mosconi-digital/image/upload/c_scale,f_auto,w_250/v1637189264/Codevelopers/web/instagram/posts/12_fpwoyc.jpg",
    link: "https://www.instagram.com/p/CT5X_LdPnC_",
  },
];

const Instagram: React.FC = () => {
  const [posts, setPosts] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const width = document.documentElement.clientWidth;

      getPosts();
      setPosts(getMockPosts());
    }
  }, []);

  const getPosts = async () => {
    setLoading(true);
    try {
      const posts = await InstagramService.getInstagramPosts();
      if (posts.length) {
        setPosts(posts);
      }
    } catch (err) {
      setPosts(getMockPosts());
    } finally {
      setLoading(false);
    }
  };

  const getMockPosts = () => {
    if (typeof window !== `undefined`) {
      const width = document.documentElement.clientWidth;
      return width <= 768 ? postsMockSm : postsMock;
    }
  };

  return (
    <section className="instagram">
      <div className="title text-center font-bold">
        <div className="container">
          <h2 className="font-bold">Instagram</h2>
        </div>
      </div>
      <div className="posts-list">
        {!loading &&
          posts.map((post, i) => {
            return (
              <a key={i} href={`${post.link}`} target="_blank">
                {post.caption ? (
                  <div className="overlay">
                    <div className="caption-container">
                      <div className="caption font-book">{post.caption}</div>
                    </div>
                  </div>
                ) : null}

                <img src={`${post.image}`} loading="lazy" />
              </a>
            );
          })}
      </div>
    </section>
  );
};

export default Instagram;
