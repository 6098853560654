import React, { useState } from "react";
// import Checkbox from "../../Contact/FormComponents/Checkbox";
import EmailInput from "../../Contact/FormComponents/EmailInput";
import NameInput from "../../Contact/FormComponents/NameInput";
import PhoneInput from "../../Contact/FormComponents/PhoneInput";
import TextArea from "../../Contact/FormComponents/TextArea";
import { contactService } from "../../../services/contact";
import { navigate } from "gatsby";
import * as styles from "./contact.module.scss";

const Contact: React.FC = () => {
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<number>();
  const [message, setMessage] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  // const [terms, setTerms] = useState<boolean>(false);
  const [formLoading, setFormLoading] = useState<boolean>(false);

  const sendEmail = (event) => {
    event.preventDefault();
    setFormLoading(true);

    contactService
      .sendEmailHome({
        name,
        phone,
        email,
        message,
      })
      .then(() => {
        navigate("/exito");
      })
      .catch(() => {})
      .finally(() => {
        setFormLoading(false);
      });
  };

  return (
    <section id="contact" className={styles.contact}>
      <div className="container">
        <div className={styles.contact__container}>
          <div className={styles.contact__container__left}>
            <div>
              <h2 className="font-medium">Contacto</h2>
              <div className={styles.contact__container__left__icons}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 242 68"
                >
                  <path
                    stroke="#1D1D1B"
                    strokeWidth="1.1"
                    d="M240.5 26.7c0 3-2.4 5.8-6.7 8a38.2 38.2 0 0 1-16.7 3.4c-6.5 0-12.4-1.3-16.6-3.4-4.3-2.2-6.7-5-6.7-8s2.4-5.8 6.7-8a38.2 38.2 0 0 1 16.6-3.4c6.6 0 12.5 1.3 16.7 3.4 4.3 2.2 6.7 5 6.7 8Z"
                  />
                  <path
                    stroke="#1D1D1B"
                    strokeWidth="1.1"
                    d="M240.5 33.3c0 3-2.4 5.9-6.7 8a38.2 38.2 0 0 1-16.7 3.5c-6.5 0-12.4-1.4-16.6-3.5-4.3-2.1-6.7-5-6.7-8s2.4-5.8 6.7-8A38.2 38.2 0 0 1 217 22c6.6 0 12.5 1.4 16.7 3.5 4.3 2.1 6.7 5 6.7 8Z"
                  />
                  <path
                    stroke="#1D1D1B"
                    strokeWidth="1.1"
                    d="M240.5 38.7c0 3-2.4 5.8-6.7 8a38.2 38.2 0 0 1-16.7 3.4c-6.5 0-12.4-1.3-16.6-3.5-4.3-2.1-6.7-5-6.7-8s2.4-5.8 6.7-8a38.2 38.2 0 0 1 16.6-3.4c6.6 0 12.5 1.4 16.7 3.5 4.3 2.1 6.7 5 6.7 8Z"
                  />
                  <path
                    stroke="#1D1D1B"
                    strokeWidth="1.1"
                    d="M240.5 44c0 3-2.4 5.8-6.7 8a38.2 38.2 0 0 1-16.7 3.4c-6.5 0-12.4-1.3-16.6-3.5-4.3-2-6.7-5-6.7-8s2.4-5.8 6.7-7.9a38.2 38.2 0 0 1 16.6-3.4c6.6 0 12.5 1.3 16.7 3.4 4.3 2.1 6.7 5 6.7 8Z"
                  />
                  <circle
                    cx="22.2"
                    cy="34.1"
                    r="5.4"
                    fill="#1D1D1B"
                    stroke="#1D1D1B"
                    strokeWidth="1.1"
                  />
                  <circle
                    cx="22.2"
                    cy="34.1"
                    r="21.4"
                    stroke="#1D1D1B"
                    strokeWidth="1.1"
                  />
                  <circle
                    cx="119"
                    cy="33.7"
                    r="21.3"
                    stroke="#1D1D1B"
                    strokeWidth="1.1"
                  />
                  <path
                    stroke="#1D1D1B"
                    strokeWidth="1.1"
                    d="M119 0v67.4M85.3 33.7h67.3"
                  />
                </svg>
              </div>
            </div>
            <div className={styles.contact__container__left__text}>
              <p>
                <span>Datos de contacto</span>
                <br />
                <span>hola@codevelopers.be</span>
                <br />
                <span>Av. del Libertador 7208, CABA</span>
                <br />
                <span>+54 9 11 7637-1547</span>
                <br />
                <span>BUENOS AIRES, ARGENTINA</span>
              </p>
            </div>
          </div>
          <div className={styles.contact__container__right}>
            <form onSubmit={(e) => sendEmail(e)}>
              <NameInput
                onNameChanged={(name) => setName(name)}
                placeholder="Tu nombre"
                helperText="Nombre y apellido"
              />
              <EmailInput
                onEmailChanged={(email) => setEmail(email)}
                placeholder="Tu email"
                helperText="Email"
              />
              <PhoneInput
                onPhoneChanged={(phone) => setPhone(phone)}
                placeholder="Tu teléfono"
                helperText="Teléfono"
              />

              <TextArea
                placeholder="Escribe tu mensaje"
                helperText="Mensaje"
                rows={5}
                onChanged={(text) => setMessage(text)}
                required={true}
              />

              <div className={styles.contact__container__right__terms}>
                <button
                  type="submit"
                  disabled={
                    formLoading || !name || !email || !phone || !message
                  }
                >
                  {formLoading ? "ENVIANDO..." : "ENVIAR"}
                  <svg
                    width="18"
                    height="14"
                    viewBox="0 0 18 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.4167 14L9.40375 13.0249L14.7729 7.7L0.5 7.7L0.5 6.3L14.7729 6.3L9.40375 0.9989L10.4167 0L17.5 7L10.4167 14Z"
                      fill="#E94F1B"
                    />
                  </svg>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
