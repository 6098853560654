import React, { useEffect, useRef, useState } from "react";
import { ComponentVersion } from "../../../interfaces/theme";
import { scrollToDiv } from "../../../services/utils";
import { SectionCoTitle } from "../Shared/SectionCoTitle";
import "./HorizontalSlider.scss";

const HorizontalSlider: React.FC<ComponentVersion> = ({ version }) => {
  const [scrollTop, setScrollTop] = useState(0);
  const [sliderPos, setSliderPos] = useState<any>({});
  const bubble1 = useRef<any>(null);

  useEffect(() => {
    const onScroll = () => {
      if (typeof window !== `undefined`) {
        const el = document.getElementById("co-experience");
        const width = document.documentElement.clientWidth;

        if (width <= 768) return;

        if (window.pageYOffset - el!.offsetTop < 0) {
          setSliderPos({
            transform: `translate3d(0vw, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
                rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
          });
          return;
        }

        const delta1 = el!.clientWidth / 95;
        const delta2 = el!.clientWidth / (300 + el!.clientWidth / 80);

        if (
          window.pageYOffset - el!.offsetTop >
          el!.clientHeight - el!.clientHeight / delta2
        ) {
          setSliderPos({
            transform: `translate3d(-200vw, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
                rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
          });
          return;
        }

        setSliderPos({
          transform: `translate3d(${
            (((window.pageYOffset - el!.offsetTop) * 3) / el!.clientHeight) *
            (-100 + delta1)
          }vw, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
                rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
        });
      }
    };
    if (typeof window !== `undefined`) {
      window.addEventListener("scroll", onScroll);
    }

    return () => {
      if (typeof window !== `undefined`) {
        return window.removeEventListener("scroll", onScroll);
      }
    };
  }, [scrollTop]);

  const scrollTo = (section: number) => {
    if (typeof window !== `undefined`) {
      if (section === 2) {
        scrollToDiv(
          "co-experience",
          1000,
          -(document.documentElement.clientWidth * 3) +
            document.documentElement.clientHeight +
            Math.hypot(
              document.documentElement.clientWidth,
              document.documentElement.clientHeight
            ) +
            document.documentElement.clientHeight / 3
        );
      } else if (section === 3) {
        scrollToDiv(
          "co-experience",
          1000,
          -(document.documentElement.clientWidth * 3) +
            document.documentElement.clientHeight
        );
      } else if (section === 4) {
        scrollToDiv("projects", 1000);
      } else {
        scrollToDiv("projects", 1000, 50);
      }
    }
  };

  return (
    <section className={`horizontal-slider v${version}`} id="co-experience">
      <div className="horizontal-scroll-wrapper">
        <div className="container">
          <div className="title-wrap">
            <SectionCoTitle title="EXPERIENCE" />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 54 45"
            >
              <path
                fill="#000"
                d="M31.3 0 28 3.1l17 17.1H0v4.5h45l-17 17 3.3 3.3 22.3-22.5L31.3 0Z"
              />
            </svg>
            <div className="spacer" />
          </div>
        </div>
        <hr />
        <div className="horizontal-scroll" ref={bubble1} style={sliderPos}>
          <div className="horizontal-scroll-inner">
            <div className="container">
              <div className="item-content flex align-center">
                <div className="item-img">
                  <img
                    className="no-select"
                    src="co-creacion.svg"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="flex-column">
                  <div className="item-title font-book">
                    CO·
                    <br />
                    CREACIÓN
                  </div>
                  <div className="item-description">
                    La inteligencia colectiva nos permite desarrollar modelos de
                    <br />
                    negocio que alinean los intereses y motivan a todos los que
                    <br />
                    conforman el equipo necesario para manifestar los proyectos.
                    <br />
                    <br />
                  </div>
                  <div className="number">
                    <span className="font-book">01</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="horizontal-scroll-inner">
            <div className="container" id="d-empatico">
              <div className="item-content flex align-center">
                <div className="item-img">
                  <img
                    className="no-select"
                    src="d-empatico.svg"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="flex-column">
                  <div className="item-title font-book">
                    COMBINAR ES
                    <br />
                    EVOLUCIONAR
                  </div>
                  <div className="item-description">
                    {/* Creemos que el diseño va mucho más allá de los planos.
                    <br />
                    Sabemos que no es lo mismo un barrio que otro y que cada
                    <br />
                    comunidad o persona es un mundo totalmente diferente. */}
                    Un equipo multidisciplinario y multidimensional.
                    <br />
                    Conformado por arquitectos, ingenieros, diseñadores,
                    <br />
                    interioristas, economistas, abogados, coachs, y muchas más
                    <br />
                    personas con perfiles realmente variados, lo que nos
                    <br />
                    permite alcanzar resultados únicos.
                  </div>
                  <div className="number">
                    <span className="font-book">02</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="horizontal-scroll-inner">
            <div className="container">
              <img
                className="arrow no-select bounce"
                src="arrow-down.svg"
                alt=""
                loading="lazy"
                onClick={() => scrollTo(4)}
              />
              <div className="item-content flex align-center">
                <div className="item-img">
                  <img
                    className="no-select"
                    src="creaciones-saludables.svg"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="flex-column">
                  <div className="item-title font-book">
                    MEJORES
                    <br />
                    PRÁCTICAS
                  </div>
                  <div className="item-description">
                    {/* Desarrollamos con la naturaleza en el centro.
                    <br />
                    Utilizamos lo último en materiales, tecnología y formas de
                    construcción.
                    <br />
                    Porque la casa de todos es una sola y hay que cuidarla. */}
                    Para crear negocios sanos, siempre debe prevalecer
                    <br />
                    la seguridad financiera, económica y jurídica. La
                    trayectoria
                    <br />
                    de las personas que conformamos Codevelopers y los asesores
                    <br />
                    profesionales que nos acompañan desde siempre comparten la
                    visión
                    <br />
                    de desarrollar únicamente bajo las mejores prácticas.
                  </div>
                  <div className="number">
                    <span className="font-book">03</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HorizontalSlider;
