import React from "react";
import Seo from "../../Shared/SEO/Seo";
import { getSeo } from "../../../services/SEO";
import { SiteConfig } from "../../../interfaces/site";
import HomeHeader from "../Header";

interface LayoutProps {
  siteConfig: SiteConfig;
  seoPage: any;
}

const Layout: React.FC<LayoutProps> = ({ children, siteConfig, seoPage }) => {
  const seoContent = getSeo(siteConfig, seoPage);

  return (
    <React.Fragment>
      <Seo content={seoContent} language={siteConfig.lang} />
      <HomeHeader siteConfig={siteConfig} />
      {children}
    </React.Fragment>
  );
};

export default Layout;
