import React, { useEffect } from "react";
import VideoLazy from "../../Shared/Video";
import "./Video.scss";

const Video: React.FC = () => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      const width = document.documentElement.clientWidth;
      const lazyVideos = [].slice.call(
        document.querySelectorAll<HTMLElement>("video.lazy")
      );

      if ("IntersectionObserver" in window) {
        const lazyVideoObserver = new IntersectionObserver((entries) => {
          entries.forEach((video: any) => {
            if (video.isIntersecting) {
              for (let source in video.target.children) {
                const videoSource: any = video.target.children[source];
                if (
                  typeof videoSource.tagName === "string" &&
                  videoSource.tagName === "SOURCE"
                ) {
                  videoSource.src =
                    width <= 768
                      ? videoSource.dataset.srcsm
                      : videoSource.dataset.src;
                }
              }

              video.target.classList.remove("lazy");
              lazyVideoObserver.unobserve(video.target);
              playVideo(video.target);
            }
          });
        });

        lazyVideos.forEach((lazyVideo) => {
          lazyVideoObserver.observe(lazyVideo);
        });
      }
    }
  }, []);

  const playVideo = async (video): Promise<void> => {
    try {
      await video.load();
      await video.play();
    } catch (err) {
      console.warn("ERROR!:", err);
    }
  };

  return (
    <section className="video-section">
      <VideoLazy
        poster="https://res.cloudinary.com/mosconi-digital/image/upload/f_auto,q_75/v1641936101/Codevelopers/web/video-poster-xl.jpg"
        url="https://res.cloudinary.com/sbc/video/upload/v1652879929/codevelopers/CODEVELOPERS_htbctc_se33wx.mp4"
        urlSm={
          "https://res.cloudinary.com/sbc/video/upload/f_auto/v1652879810/codevelopers/CODEVELOPERS-video-mobile_wnns5o_nfsnbn.mp4"
        }
      />
    </section>
  );
};

export default Video;
