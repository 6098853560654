import { ThemeColors, ThemeVariables } from "../interfaces/theme";

export const ThemeService = {
  themeVersionStorageKey: "CO_THEME_VERSION",
  numberOfVersions: 5,

  init(): void {
    const currentVersion = this.getThemeVersion();
    const themeVariables: ThemeVariables =
      this.getThemeVariables(currentVersion);

    this.setColors(themeVariables);
  },

  getColorList(): ThemeColors {
    return {
      pink: "#F69EAA",
      aqua: "#348090",
      green: "#165937",
      red: "#DF432A",
      ocre: "#E94F1B",
      yellow: "#F8B900",
    };
  },

  hexToRgb(hex: string): string {
    return hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (_, r, g, b) => "#" + r + r + g + g + b + b
      )
      .substring(1)
      .match(/.{2}/g)
      .map((x) => parseInt(x, 16))
      .join();
  },

  getThemeVersion(): number {
    // const currentVersion =
    //   +localStorage.getItem(this.themeVersionStorageKey) || 0;
    // const nextVersion = currentVersion + 1;
    // return nextVersion > this.numberOfVersions ? 1 : nextVersion;
    return 5;
  },

  setThemeVersion(version: number): void {
    localStorage.setItem(this.themeVersionStorageKey, `${version}`);
  },

  setColors(themeVariables: ThemeVariables): void {
    document.documentElement.style.setProperty(
      "--primary",
      themeVariables?.primary
    );
    document.documentElement.style.setProperty(
      "--primary-rgb",
      themeVariables?.primaryRgb
    );
    document.documentElement.style.setProperty(
      "--secondary",
      themeVariables?.secondary
    );
  },

  getThemeVariables(version: number): ThemeVariables {
    let themeVariables!: ThemeVariables;
    const colors: ThemeColors = this.getColorList();

    switch (version) {
      case 1:
        themeVariables = {
          primary: colors.pink,
          primaryRgb: this.hexToRgb(colors.pink),
          secondary: colors.yellow,
        };
        break;

      case 2:
        themeVariables = {
          primary: colors.green,
          primaryRgb: this.hexToRgb(colors.green),
          secondary: colors.pink,
        };
        break;

      case 3:
        themeVariables = {
          primary: colors.aqua,
          primaryRgb: this.hexToRgb(colors.aqua),
          secondary: colors.ocre,
        };
        break;

      case 4:
        themeVariables = {
          primary: colors.red,
          primaryRgb: this.hexToRgb(colors.red),
          secondary: colors.aqua,
        };
        break;

      case 5:
        themeVariables = {
          primary: colors.ocre,
          primaryRgb: this.hexToRgb(colors.yellow),
          secondary: colors.yellow,
        };
        break;

      default:
        break;
    }

    return themeVariables;
  },
};
