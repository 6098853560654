import React, { useEffect } from "react";
import HomeByEveryone from "./ByEveryone";
import HomeHero from "./Hero";
import Video from "./Video";
import HorizontalSlider from "./HorizontalSlider";
import Projects from "./Projects";
import Community from "./Community";
import Instagram from "./Instagram";
import Footer from "./Footer";
import { SiteConfig } from "../../interfaces/site";
import { scaleText } from "../../services/utils";
import ContactButton from "../Shared/ContactButton";
import Prensa from "./Prensa";
import "./home.scss";
import Contact from "./Contact";

interface HomeProps {
  version: number;
  siteConfig: SiteConfig;
}

const HomePage: React.FC<HomeProps> = ({ version }) => {
  useEffect(() => {
    // timeout
    setTimeout(() => {
      scaleText();
      if (typeof window !== `undefined`) {
        window.addEventListener("resize", scaleText, false);
      }
    }, 100);
  }, [version]);

  return (
    <div className="home-page" key={version}>
      <HomeHero version={version} />
      <HomeByEveryone version={version} />
      <Video />
      <HorizontalSlider version={version} />
      <Projects version={version} />
      <Community version={version} />
      <Prensa />
      <Instagram />
      {/* <Footer version={version} /> */}
      <ContactButton projectName={""} />
      <Contact />
    </div>
  );
};

export default HomePage;
