import React from "react";
import Carousel from "react-grid-carousel";
import "./prensa.scss";

const items = [
  {
    title: "Ahora podés caminar por tu departamento antes de que se construya",
    subtitle:
      " Una desarrolladora utiliza una tecnología que permite vivir el espacio antes de que exista",
    logo: "la-nacion.svg",
    logoAlt: "La Nación",
    logoClass: "",
    link: "https://www.lanacion.com.ar/propiedades/construccion-y-diseno/ahora-podes-caminar-por-tu-futuro-departamento-antes-de-que-se-construya-nid15122021/",
  },
  {
    title: "La colaboración como valor agregado",
    subtitle:
      "Como funciona Codevelopers, una desarrolladora joven con diferentes modelos de negocio",
    logo: "clarin.svg",
    logoAlt: "Clarin",
    logoClass: "sm",
    link: "https://res.cloudinary.com/mosconi-digital/image/upload/f_auto/v1644543073/Codevelopers/web/Clari%CC%81n_ARQ_7-12-21_Pg43_xhjgkz.png",
  },
  {
    title:
      "La realidad virtual de los videojuegos pone un pie en el real estate",
    subtitle:
      "Codevelopers anunció el lanzamiento de su primer proyecto modelado íntegramente en 3D",
    logo: "iproup.svg",
    logoAlt: "iProUp",
    logoClass: "sm",
    link: "https://www.iproup.com/economia-digital/32335-realidad-virtual-en-videojuegos-asi-llegan-al-real-estate",
  },
  {
    title: "Los amenities más demandados",
    subtitle:
      "Manuel Depierro, director de Proyecto de Codevelopers, señala los puntos a tener en cuenta.",
    logo: "iprofesional.svg",
    logoAlt: "iProfesional",
    logoClass: "",
    link: "https://www.iprofesional.com/negocios/368097-amenities-en-edificios-cuales-son-las-mas-demandadas",
  },
  {
    title: "Innovaciones tecnológicas para el sector inmobiliario",
    subtitle:
      "“No queremos vender departamentos, sino co-crearlos con nuestro público”",
    logo: "argenprop.svg",
    logoAlt: "Argenprop",
    logoClass: "md",
    link: "https://noticias.argenprop.com/actualidad/innovaciones-tecnologicas-para-el-sector-inmobiliario-que-facilitan-un-mejor-estilo-de-vida/",
  },
  {
    title:
      "Punta del Este, un imán para proyectos de viviendas y familias argentinas",
    subtitle:
      " El mundo está empezando a ver Punta del Este como un Hub de conocimiento",
    logo: "infobae.svg",
    logoAlt: "Infobae",
    logoClass: "sm",
    link: "https://www.infobae.com/economia/2021/09/06/punta-del-este-un-iman-para-proyectos-de-viviendas-y-familias-argentinas-que-deciden-radicarse-en-la-otra-orilla/",
  },
];

const Prensa: React.FC = () => {
  return (
    <section className="prensa" id="prensa">
      <div className="container">
        <div className="title text-center">
          <h2 className="font-bold">Prensa</h2>
        </div>
      </div>
      {/* <div className="container"> */}
      <div className="prensa-carousel">
        {/* {items.map((item, idx) => {
            return (
              <div className="item" key={idx}>
                <a href={item.link} target="_blank">
                  <div className="item-logo-wrap">
                    <img
                      className={`item-logo ${item.logoClass}`}
                      src={item.logo}
                      alt={item.logoAlt}
                    />
                  </div>
                  <div className="item-subtitle">
                    <span className="font-bold">{item.title}</span>
                  </div>
                  <div className="item-pharagraph">{item.subtitle}</div>
                  <button className="read-note font-bold">
                    Leer más{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 27 14"
                    >
                      <path
                        fill="#1D1D1B"
                        d="m20 14-1.1-1 5.4-5.3H0V6.3h24.3L18.9 1l1-1L27 7l-7 7Z"
                      />
                    </svg>
                  </button>
                </a>
              </div>
            );
          })} */}

        {/* ----------- */}

        <Carousel
          cols={3}
          rows={1}
          gap={40}
          loop
          hideArrow={false}
          // scrollSnap={false}
          autoplay={5000}
          showDots={true}
          // containerStyle={{
          //   overflow: "visible",
          // }}
          mobileBreakpoint={300}
          dot={({ isActive }) => (
            <span
              className="carousel-dot"
              style={{
                background: isActive ? "var(--primary)" : "#f2f2f2",
              }}
            ></span>
          )}
          arrowRight={() => (
            <div className="arrow-control arrow-right">
              <img src="chevron-right.svg" />
            </div>
          )}
          arrowLeft={() => (
            <div className="arrow-control arrow-left">
              <img src="chevron-left.svg" />
            </div>
          )}
          responsiveLayout={[
            {
              breakpoint: 800,
              cols: 2,
            },
            {
              breakpoint: 600,
              cols: 1,
              rows: 3,
              gap: 30,
            },
          ]}
        >
          {items.map((item, idx) => {
            return (
              <Carousel.Item key={idx}>
                <div className="item" key={idx}>
                  <a href={item.link} target="_blank">
                    <div className="item-logo-wrap">
                      <img
                        className={`item-logo ${item.logoClass}`}
                        src={item.logo}
                        alt={item.logoAlt}
                      />
                    </div>
                    <div className="item-subtitle">
                      <span className="font-bold">{item.title}</span>
                    </div>
                    <div className="item-pharagraph">{item.subtitle}</div>
                    <button className="read-note font-bold">
                      Leer más{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 27 14"
                      >
                        <path
                          fill="#1D1D1B"
                          d="m20 14-1.1-1 5.4-5.3H0V6.3h24.3L18.9 1l1-1L27 7l-7 7Z"
                        />
                      </svg>
                    </button>
                  </a>
                </div>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>

      <div className="container">
        <div className="logo-wrap">
          <img className="logo" src="new-logo.svg" alt="Ámbito" />
        </div>
      </div>
    </section>
  );
};

export default Prensa;
