import axios from "axios";
import { clientConfig } from "./client-config";

export const InstagramService = {
  getInstagramPosts() {
    return axios
      .get(clientConfig.apiUrl + "/ig/getPosts")
      .then((response) => {
        // const isEmpty = !!!response.data.data[0]?.src
        // return !isEmpty ? response.data.data : []
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
