import { navigate } from "gatsby";
import React from "react";
import { ComponentVersion } from "../../../interfaces/theme";
import "./projects.scss";

const items = [
  {
    id: "pi-urquiza",
    title: "Pi Urquiza",
    address: "Andonaegui 2042, Villa Urquiza, CABA.",
    image:
      "https://res.cloudinary.com/mosconi-digital/image/upload/f_auto/v1680307898/Codevelopers/web/Proyectos%20home/pi-urquiza_xrmdtu.jpg",
  },
  {
    id: "set-palermo",
    title: "Set Palermo",
    address: "Gurruchaga 1251, Palermo, CABA.",
    image:
      "https://res.cloudinary.com/mosconi-digital/image/upload/f_auto/v1680307902/Codevelopers/web/Proyectos%20home/set-palermo_wlszpi.jpg",
  },
  {
    id: "pi-caballito",
    title: "Pi Caballito",
    address: "Hualfin 1196, CABA, Argentina",
    image:
      "https://res.cloudinary.com/mosconi-digital/image/upload/f_auto/v1680307909/Codevelopers/web/Proyectos%20home/pi-caballito_fajgh7.jpg",
  },
  {
    id: "",
    title: "Vibra Zamá",
    address: "Tulum, México.",
    image:
      "https://res.cloudinary.com/mosconi-digital/image/upload/f_auto/v1680307913/Codevelopers/web/Proyectos%20home/vibra-zama_klruqj.jpg",
  },
  // {
  //   id: "azure",
  //   title: "Azure Ocean Reserve",
  //   address: "Playa Mansa, Punta del Este, Uruguay.",
  //   image:
  //     "https://res.cloudinary.com/mosconi-digital/image/upload/f_auto/v1680307917/Codevelopers/web/Proyectos%20home/azure_e8ok9g.jpg",
  // },
];

const Projects: React.FC<ComponentVersion> = ({ version }) => {
  return (
    <section className={`projects v${version}`} id="projects">
      <h2 className="title font-book">Proyectos</h2>

      <div className="project-container">
        <div
          className={`project-carousel ${items.length < 6 ? "centered" : ""}`}
        >
          {items.map((item, idx) => {
            return (
              <div
                key={idx}
                className="project-item"
                onClick={() => (item.id ? navigate(item.id) : null)}
              >
                <img src={item.image} className="project-item-image" />
                <div className="project-item-content">
                  <div className="project-item-title font-bold">
                    <img src="marker.svg" className="marker" />
                    {item.title}
                  </div>
                  <div className="project-item-address font-book">
                    {item.address}
                  </div>

                  <button
                    className="font-bold"
                    onClick={() => (item.id ? navigate(item.id) : null)}
                    disabled={!item.id}
                  >
                    {item.id ? (
                      <>
                        Ver más{" "}
                        <svg
                          width="18"
                          height="14"
                          viewBox="0 0 18 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.4167 14L9.40375 13.0249L14.7729 7.7L0.5 7.7L0.5 6.3L14.7729 6.3L9.40375 0.9989L10.4167 0L17.5 7L10.4167 14Z"
                            fill="#1c1c1c"
                          ></path>
                        </svg>
                      </>
                    ) : (
                      <span>Coming soon</span>
                    )}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Projects;
