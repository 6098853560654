import React from "react";
import "./style.scss";

interface OutlinedTextProps {
  text: string;
}

export const OutlinedText: React.FC<OutlinedTextProps> = ({ text }) => {
  return <div className="outlined-text font-book">{text}</div>;
};
